//ie11
objectFitImages();
$(function(){
	//ブラウザ判定
	var ua = UAParser()
	var uaString = {
		browserName: ua.browser.name.toLowerCase().replace(' ', '-'),
		browserVersion: ua.browser.major,
		browserEngine: ua.engine.name.toLowerCase().replace(' ', '-'),
		osName: ua.os.name.toLowerCase().replace(' ', '-'),
		type: (typeof ua.device.type !== 'undefined') ? ua.device.type.toLowerCase().replace(' ', '-') : 'laptop'
	}
	$('html').attr('browser', uaString.browserName)
	$('html').attr('browserversion', uaString.browserVersion)
	$('html').attr('browserengine', uaString.browserEngine)
	$('html').attr('os', uaString.osName)
	$('html').attr('type', uaString.type)
	
	/**
	 * SPグロナビ開閉
	 */
	$('.js-gnav').click(function () {
		$('.js-sp_menu').stop().fadeToggle();
		$(this).toggleClass('is-open')
		return false;
	})

	/**
	 * キャンペーンの高さ分スクロールオフセット
	 */
	$(window).scroll(function () {
		if ($(window).scrollTop() > $('.l-hdr__campaign').height()) {
			$('.l-hdr-main').addClass('is-fixed')
		} else {
			$('.l-hdr-main').removeClass('is-fixed')
		}
	}).scroll();

	/**
	 * SP時キーワード表示切り替え
	 */
	$('.js-showKeywordSp').click(function () {
		$('.l-hdr-main__spkeyword').stop().fadeToggle();
		return false;
	})
	$('.js-hideKeywordSp').click(function () {
		$('.l-hdr-main__spkeyword').stop().fadeToggle();
		return false;
	})

	//scroll
	$('a[href^="#"]').click(function () {
		var speed = 500;
		var href = $(this).attr("href");
		if ($(this).hasClass('js-pagetop')) {
			href = 'body';
		}
		var target = $(href);
		var position = target.offset().top;
		$("html, body").animate({ scrollTop: position }, speed, "swing");
		return false;
	});

	$(window).scroll(function () {
		if ($(window).scrollTop() > 100) {
			$('.js-pagetop').stop().fadeTo(300, 1);
		} else {
			$('.js-pagetop').stop().fadeTo(200, 0, function () {
				$('.js-pagetop').hide();
			});
		}
	}).scroll();

	/**
	 * メニューの詳細を見るボタン
	 * 押下でモーダル表示
	 * サービス一覧と詳細で使用
	 */
	$('.js-modal_btn').click(function () {
		var target = $(this).attr('href');
		$(target).addClass('is-show');
		return false;
	})
	$('.js-modal_close').click(function () {
		$('.c-modal').removeClass('is-show')
		return false;
	})

	/**
	 * 検索バーのJS
	 */

	//エリア
	$('.js-search input[type="checkbox"]').change(function () {
		var counter = 0;
		var parents = $(this).parents('.js-search')
		$(parents).find('input[type="checkbox"]').each(function (index, el) {
			if ($(el).prop('checked')) {
				counter++;
			}
		})
		$(parents).find('.js-count').text(counter)
	})
	// $('.js-searchArea input[type="radio"]').change(function(){
	// 	if ($('.js-searchArea input[type="radio"]:checked').hasClass('js-syutoken')) {
	// 		$('.js-searchArea input[type="checkbox"]').prop('disabled',false);
	// 	}else {
	// 		$('.js-searchArea input[type="checkbox"]').prop('disabled', true);
	// 		$('.js-searchArea input[type="checkbox"]').prop('checked', false);
	// 	}
	// })
	// $('.js-searchArea input[type="radio"],.js-searchArea input[type="checkbox"]').change(function () {
	// 	var counter = 0;
	// 	$('.js-searchArea input[type="radio"]').each(function (index, el) {
	// 		if ($(el).prop('checked') && !$(el).hasClass('js-syutoken')) {
	// 			counter++;
	// 		}
	// 	})
	// 	$('.js-searchArea input[type="checkbox"]').each(function (index, el) {
	// 		if ($(el).prop('checked')) {
	// 			counter++;
	// 		}
	// 	})
	// 	$('.js-searchArea .js-count').text(counter)
	// })
	// //目的
	// $('.js-searchPurpose input[type="checkbox"]').change(function () {
	// 	var counter = 0;
	// 	$('.js-searchPurpose input[type="checkbox"]').each(function (index, el) {
	// 		if ($(el).prop('checked')) {
	// 			counter++;
	// 		}
	// 	})
	// 	$('.js-searchPurpose .js-count').text(counter)
	// })
	// //サービス
	// $('.js-searchService input[type="checkbox"]').change(function () {
	// 	var counter = 0;
	// 	$('.js-searchService input[type="checkbox"]').each(function (index, el) {
	// 		if ($(el).prop('checked')) {
	// 			counter++;
	// 		}
	// 	})
	// 	$('.js-searchService .js-count').text(counter)
	// })
	//ドロップダウン
	$('.js-searchDropDown').click(function(){
		$('.l-search-form .list').not(this).stop().slideUp();
		$(this).next('.list').stop().slideToggle();
		return false;
	})
	$('.js-closeSearchDropDown').click(function(){
		$(this).parents('.list').stop().slideToggle();
		return false;
	})
})